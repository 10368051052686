import React from "react";

// functions
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

type ProgressBarProps = {
  progress: number;
};
type ProgressBarStates = {};

class ProgressBar extends React.Component<ProgressBarProps, ProgressBarStates> {
  progressBarContainer!: HTMLElement;

  componentDidMount() {
    this.progressBarContainer = document.querySelector<HTMLElement>(
      "#progressBarContainer"
    )!;
    disableBodyScroll(this.progressBarContainer);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    return (
      <div
        id="progressBarContainer"
        className="absolute bottom-0 flex w-screen overflow-hidden bg-gray-600 opacity-25"
        style={{
          height: `${this.props.progress}%`,
          touchAction: "none",
        }}
      ></div>
    );
  }
}

export default ProgressBar;
