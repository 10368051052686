import React from "react";

// components
import { Helmet } from "react-helmet";

//
function NotFound() {
  return (
    <div className="NotFound">
      <Helmet>
        <title>Not Found | blindtest</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <body className="bg-red-900"></body>
      </Helmet>
      <div className="flex items-center justify-center h-screen">
        <h1 className="text-4xl font-extrabold text-red-100 underline">
          404 - NotFound
        </h1>
      </div>
    </div>
  );
}

export default NotFound;
