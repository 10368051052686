import React from "react";

// components
import { Helmet } from "react-helmet";

// images

//
function Complete() {
  return (
    <div>
      <Helmet>
        <title>Complete | blindtest</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <body className="bg-gray-900"></body>
      </Helmet>
      <div className="text-center">
        <h1 className="mt-20 text-4xl font-bold text-gray-200">Complete</h1>
        <p className="mt-12 text-xl font-semibold text-gray-300">
          Thank you!
          <br />
          You have completed all tasks.
        </p>
      </div>
    </div>
  );
}

export default Complete;
