import React from "react";

// images
import bird from "../assets/img/cards/bird.png";

type LoadingAnimationProps = {
  isLoading: boolean;
};
type LoadingAnimationStates = {};

class LoadingAnimation extends React.Component<
  LoadingAnimationProps,
  LoadingAnimationStates
> {
  loadingAnimationContainer!: HTMLElement;
  cardImage!: HTMLElement;

  componentDidUpdate() {
    this.cardImage = document.querySelector<HTMLElement>("#cardImage")!;

    if (!this.cardImage) {
      return;
    }

    var src = this.cardImage.getAttribute("src");
    if (!src) {
      src = bird;
    }

    if (document.querySelector<HTMLElement>("#loadingAnimation")) {
      document
        .querySelector<HTMLElement>("#loadingAnimation")!
        .setAttribute("src", src);
    }
  }

  render() {
    if (this.props.isLoading) {
      return (
        <div>
          <div
            id="loadingAnimationContainer"
            className="absolute top-0 w-screen h-screen overflow-hidden bg-teal-900 opacity-75"
          ></div>
          <div
            id="loadingAnimationContainer"
            className="absolute top-0 flex items-center justify-center w-screen h-screen overflow-hidden"
            style={{
              touchAction: "none",
            }}
          >
            <div className="absolute object-left-top bg-white rounded-full">
              <img
                id="loadingAnimation"
                src={bird}
                alt="Loading spinner"
                className="m-4 select-none max-w-none animate-spin"
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default LoadingAnimation;
