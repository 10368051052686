import React from "react";

// components
import { Helmet } from "react-helmet";

//
function Invalid() {
  return (
    <div className="Invalid">
      <Helmet>
        <title>Not Found | blindtest</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <body className="bg-teal-900"></body>
      </Helmet>
      <div className="flex items-center justify-center h-screen">
        <h1 className="mx-4 text-4xl font-extrabold text-teal-100 underline">
          500 - Invalid&nbsp;DeviceId
        </h1>
      </div>
    </div>
  );
}

export default Invalid;
