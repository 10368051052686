import React from "react";
import { Redirect } from "react-router-dom";

// components
import { Helmet } from "react-helmet";

// images

//
type WelcomeProps = {
  match: any;
  id: string;
};
type WelcomeStates = {
  redirect: any;
};

class Home extends React.Component<WelcomeProps, WelcomeStates> {
  constructor(props: WelcomeProps) {
    super(props);

    this.state = {
      redirect: null,
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div>
        <Helmet>
          <title>blindtest</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <body className="bg-gray-900"></body>
        </Helmet>

        <div className="text-center">
          <h1 className="mt-20 text-4xl font-bold text-gray-200">Welcome</h1>
          <p className="mt-12 text-xl leading-loose text-gray-300">
            Thanks for participating.
            <br />
            Please click <span className="uppercase">start</span> to begin.
          </p>
          <button
            className="px-4 py-2 mt-16 font-semibold text-gray-800 uppercase bg-gray-100 rounded shadow"
            type="button"
            onClick={() => {
              this.setState({
                redirect: {
                  pathname: "/swipe",
                  state: {
                    id: this.props.match.params.id,
                  },
                },
              });
            }}
          >
            Start
          </button>
          <p className="mt-4 text-xs text-gray-300">
            id:{" "}
            <span className="font-semibold">{this.props.match.params.id}</span>
          </p>
        </div>
      </div>
    );
  }
}

export default Home;
