import React from "react";

// functions
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

// images
import { Wifi, WifiOff } from "react-feather";

type NetworkIndicatorProps = {
  status: string;
};
type NetworkIndicatorStates = {};

class NetworkIndicator extends React.Component<
  NetworkIndicatorProps,
  NetworkIndicatorStates
> {
  networkIndicatorContainer!: HTMLElement;

  componentDidMount() {
    this.networkIndicatorContainer = document.querySelector<HTMLElement>(
      "#networkIndicatorContainer"
    )!;
    disableBodyScroll(this.networkIndicatorContainer);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    const status = this.props.status;

    if (status === "ok") return null;

    let icon;

    if (status === "slow") {
      icon = <Wifi color="orange" size={40} />;
    } else {
      // offline
      icon = <WifiOff color="red" size={40} />;
    }

    return (
      <div
        id="networkIndicatorContainer"
        className="absolute top-0 flex justify-center w-full m-4"
        style={{
          touchAction: "none",
        }}
      >
        {icon}
      </div>
    );
  }
}

export default NetworkIndicator;
