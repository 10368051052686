import React from "react";
import { Redirect } from "react-router-dom";

import {
  PrismaSDK,
  UsabilityResponse,
  ConnectivityResponse,
  ConnectivityStatus,
} from "@prismadelabs/prismaid";
import anime from "animejs";

// components
import { Helmet } from "react-helmet";
import ScaledImage from "./ScaledImage";
import CardImage from "./CardImage";
import ProgressBar from "./ProgressBar";
import NetworkIndicator from "./NetworkIndicator";

// functions
import { alignHorizontally, alignVertically } from "../js/align";

import { getScaleFactor } from "../js/scale";

// images
import frame from "../assets/img/swipe/scan_frame.png";
import bat from "../assets/img/cards/bat.png";
import butterfly from "../assets/img/cards/butterfly.png";
import eagle from "../assets/img/cards/eagle.png";
import fish from "../assets/img/cards/fish.png";
import frog from "../assets/img/cards/frog.png";
import ladybug from "../assets/img/cards/ladybug.png";
import monkey from "../assets/img/cards/monkey.png";
import pig from "../assets/img/cards/pig.png";
import snake from "../assets/img/cards/snake.png";
import unicorn from "../assets/img/cards/unicorn.png";

//
type SwipeScreenProps = {
  setReset: any;
  setSubmit: any;
  setDeviceSupport: any;
  setBrowserSupport: any;
  setIsLoading: any;
  orientation: string;
  apiKey: string;
  cardName: string;
  message: string;
  enableButton: any;
  disableButton: any;
  deviceId: string;
};

type SwipeScreenStates = {
  scaleFactor: number;
  redirect: any;
  apiKey: string;
  cardName: string;
  src: string;
  message: string;
  progress: number;
  networkStatus: string;
};

class SwipeScreen extends React.Component<SwipeScreenProps, SwipeScreenStates> {
  private sdk: PrismaSDK;

  controller = new AbortController();
  signal = this.controller.signal;

  constructor(props: SwipeScreenProps) {
    super(props);

    this.state = {
      // divided by 2, because @2 images are used
      scaleFactor: 0.5,
      redirect: null,
      apiKey: this.props.apiKey,
      cardName: this.props.cardName,
      src: "",
      message: this.props.message,
      progress: 0,
      networkStatus: "",
    };

    this.sdk = this.prepareSDK();

    // not mobile
    if (!/Mobi|Android/i.test(navigator.userAgent)) {
      console.log("Device is not mobile");
      this.props.setDeviceSupport(false);
    }
    this.signal.addEventListener("abort", () => {
      console.log("aborted!");
    });
  }

  setCardImageSource = () => {
    switch (this.props.cardName) {
      case "bat":
        this.setState({ src: bat });
        break;
      case "butterfly":
        this.setState({ src: butterfly });
        break;
      case "eagle":
        this.setState({ src: `${eagle}` });
        break;
      case "fish":
        this.setState({ src: fish });
        break;
      case "frog":
        this.setState({ src: frog });
        break;
      case "ladybug":
        this.setState({ src: ladybug });
        break;
      case "monkey":
        this.setState({ src: monkey });
        break;
      case "pig":
        this.setState({ src: pig });
        break;
      case "snake":
        this.setState({ src: snake });
        break;
      case "unicorn":
        this.setState({ src: unicorn });
        break;
      default:
        this.setState({ src: "" });
        break;
    }
  };
  componentDidMount() {
    this.initialisePrismaSDK();

    // set action for buttons from parentPage
    this.props.setReset(this.reset);
    this.props.setSubmit(this.submit);

    this.setCardImageSource();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.orientation !== prevProps.orientation) {
      console.log("componentDidUpdate: orientation", this.props.orientation);
      window.location.reload(false);
    }

    if (
      this.props.apiKey !== prevProps.apiKey ||
      this.props.cardName !== prevProps.cardName ||
      this.props.message !== prevProps.message
    ) {
      this.prepareSDK();
      this.initialisePrismaSDK();
      this.setCardImageSource();
    }
  }

  componentWillUnmount() {
    this.sdk.getInitialisationSubject().unsubscribe();
    this.sdk.getProgressSubject().unsubscribe();
    this.sdk.getUsabilitySubject().unsubscribe();
    this.sdk.getDetectionSuccessSubject().unsubscribe();
    this.sdk.getDetectionErrorSubject().unsubscribe();
    this.controller.abort();
  }

  prepareSDK() {
    // FIXME: remove API url for production use!
    this.sdk = new PrismaSDK(
      this.props.apiKey,
      "https://api-dev.prismade.net/prismaid"
    );

    this.sdk.getInitialisationSubject().subscribe((response) => {
      var scale = getScaleFactor(response.ppi, response.devicePixelRatio);

      if (!Number.isNaN(scale)) {
        this.setState({ scaleFactor: scale });
      }
    });

    this.sdk.getProgressSubject().subscribe((response) => {
      console.log("*) progress:", response.progress);
      this.setState({ progress: response.progress });
      if (response.progress > 80) {
        this.props.enableButton();
      }
    });

    this.sdk.getUsabilitySubject().subscribe((response: UsabilityResponse) => {
      // device not supported
      if (response.event === "device_not_supported") {
        console.log("Device is not supported");
        this.props.setDeviceSupport(false);
      }

      // browser_not_supported (->android firefox)
      if (response.event === "browser_not_supported") {
        console.log("Browser is not supported");
        this.props.setBrowserSupport(false);
      }
    });

    this.sdk
      .getConnectivitySubject()
      .subscribe((response: ConnectivityResponse) => {
        console.log("*) connectivity response:", response.status);

        if (response.status === null) return;

        switch (response.status) {
          case ConnectivityStatus.ok:
            this.setState({ networkStatus: "ok" });
            break;

          case ConnectivityStatus.slow:
            this.setState({ networkStatus: "slow" });
            break;

          case ConnectivityStatus.offline:
            this.setState({ networkStatus: "offline" });
            break;
        }
      });

    return this.sdk;
  }

  saveResult = (res: any) => {
    const data = {
      deviceInfo: {
        deviceId: this.props.deviceId,
      },
      taskInfo: {
        cardName: this.props.cardName,
        result: {
          ...res,
          ...{ installationID: this.sdk.getUUID() },
        },
      },
    };

    console.log("data", data);

    this.props.setIsLoading(true);

    fetch(
      "https://0gkk49qd4d.execute-api.eu-west-1.amazonaws.com/prod/blindtestapp/nexttask",
      {
        mode: "cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
        signal: this.signal,
      }
    )
      .then((result) => result.json())
      .then(
        (result) => {
          console.log("result", result);
          window.location.reload(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  initialisePrismaSDK = () => {
    this.sdk.getDetectionSuccessSubject().subscribe((response) => {
      console.log("*) detection success:", response.description());
      this.saveResult(response.rawData);
    });

    this.sdk.getDetectionErrorSubject().subscribe((response) => {
      console.log("*) detection error:", response.description());

      response.hints.forEach((hint) => {
        console.log("*) hint:", hint.description());
      });

      this.saveResult(response);
    });

    this.sdk.getInitialisationSubject().subscribe((response) => {
      // INFO: prepareUI cannot be called before component is mounted, as elements might not have a width/height
      this.prepareUI();
    });

    const screen = document.querySelector("#swipeScreen");
    if (screen) {
      this.sdk.attachToElement(screen);
    }
  };

  calibrateCardBounds = () => {
    const card = document.getElementById("frame");
    if (!card) return;

    const cardRect = card.getBoundingClientRect();
    console.log("card boundingClientRect", cardRect);
    const screen = document.querySelector("#swipeScreen");
    if (screen) {
      this.sdk.attachToElement(screen);
    }
  };

  prepareUI = () => {
    // align images
    alignHorizontally("frame", "center");
    alignVertically("frame", "center");

    alignHorizontally("cardIconContainer", "center");
    alignVertically("cardIconContainer", "center");

    this.calibrateCardBounds();

    this.showFixedImages();
  };

  showFixedImages = () => {
    anime({
      targets: ["#frame", "#cardImage", "#cardIconContainer"],
      opacity: 1.0,
      duration: 200,
      easing: "linear",
    });
  };

  getScaled = (x: number): number => {
    return x * this.state.scaleFactor;
  };

  reset = () => {
    this.sdk.resetManual();
    this.props.disableButton();
  };

  submit = () => {
    this.sdk.sendManual();
  };

  invalidDeviceId = (): boolean => {
    if (this.props.message === "invalid deviceId") {
      return true;
    }
    return false;
  };

  noNextTask = (): boolean => {
    if (this.props.message === "All tasks completed!") {
      return true;
    }
    return false;
  };

  render() {
    // const status = this.state.networkStatus;
    let status = this.state.networkStatus;
    // let status = "offline";
    let indicator;

    if (status === "slow") {
      indicator = <NetworkIndicator status="slow" />;
    } else if (status === "offline") {
      // offline
      indicator = <NetworkIndicator status="offline" />;
    } else {
      indicator = null;
    }

    if (this.invalidDeviceId()) {
      return <Redirect to="/invalid" />;
    }

    if (this.noNextTask()) {
      return <Redirect to="/complete" />;
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div
        id="swipeScreen"
        className="absolute top-0 left-0 w-screen h-screen overflow-hidden"
      >
        <Helmet>
          <meta
            name="viewport"
            content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />

          <meta name="format-detection" content="telephone=no" />
          <meta name="msapplication-tap-highlight" content="no" />

          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="Prisma Key" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        </Helmet>

        <ProgressBar progress={this.state.progress} />

        {indicator}

        <ScaledImage
          src={frame}
          id="frame"
          alt="frame"
          scaleFactor={this.state.scaleFactor}
        />
        <p className="py-6 mt-32 font-medium text-center text-gray-200">
          Please swipe card
          <span className="ml-1 text-lg font-bold">{this.props.cardName}</span>
        </p>
        <CardImage
          src={this.state.src}
          id="cardImage"
          alt={this.props.cardName}
          scaleFactor={1}
        />
      </div>
    );
  }
}

export default SwipeScreen;
