import React from "react";
import { Redirect } from "react-router-dom";
import DeviceOrientation, { Orientation } from "react-screen-orientation";

// components
import { Helmet } from "react-helmet";
import SwipeScreen from "../components/SwipeScreen";
import NotSupportedModal from "../components/modals/NotSupportedModal";
import LoadingAnimation from "../components/LoadingAnimation";

// functions
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

// images
import rotateDevice from "../assets/img/alerts/rotate-device.png";

//
type SwipeProps = {
  location: any;
};
type SwipeStates = {
  redirect: any;
  orientation: string;
  deviceIsSupported: boolean;
  browserIsSupported: boolean;
  submitIsDisabled: boolean;
  isLoading: boolean;
  nextApiKey: string;
  nextCardName: string;
  message: string;
};

class Swipe extends React.Component<SwipeProps, SwipeStates> {
  swipeElement!: HTMLElement;
  overlay!: HTMLElement;
  click: any;

  controller = new AbortController();
  signal = this.controller.signal;

  constructor(props: SwipeProps) {
    super(props);

    this.state = {
      redirect: null,
      orientation: "portrait",
      deviceIsSupported: true,
      browserIsSupported: true,
      submitIsDisabled: true,
      isLoading: false,
      nextApiKey: "",
      nextCardName: "",
      message: "",
    };

    this.getNextTask();

    this.signal.addEventListener("abort", () => {
      console.log("aborted!");
    });
  }

  componentDidMount() {
    this.swipeElement = document.querySelector<HTMLElement>("#swipe")!;
    disableBodyScroll(this.swipeElement);

    this.overlay = document.querySelector<HTMLElement>("#overlay")!;
    disableBodyScroll(this.overlay);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
    this.controller.abort();
  }

  clickReset = (click: any) => {
    this.clickReset = click;
  };

  clickSubmit = (click: any) => {
    this.clickSubmit = click;
  };

  setDeviceIsSupported = (deviceIsSupported: boolean) => {
    this.setState({ deviceIsSupported: deviceIsSupported });
  };

  setBrowserIsSupported = (browserIsSupported: boolean) => {
    this.setState({ browserIsSupported: browserIsSupported });
  };

  setIsLoading = (loading: boolean) => {
    this.setState({ isLoading: loading });
  };

  showNotSupportedModal = (): boolean => {
    if (!this.state.deviceIsSupported) return true;
    if (!this.state.browserIsSupported) return true;

    return false;
  };

  getNextTask = () => {
    if (this.props.location.state === undefined) {
      this.setState({ redirect: "/invalid" });
      return;
    }
    const data = {
      deviceInfo: {
        deviceId: this.props.location.state.id,
      },
    };

    console.log("data", data);

    fetch(
      "https://0gkk49qd4d.execute-api.eu-west-1.amazonaws.com/prod/blindtestapp/nexttask",
      {
        mode: "cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
        signal: this.signal,
      }
    )
      .then((result) => result.json())
      .then(
        (result) => {
          console.log("result", result);
          this.setState({
            nextApiKey: result.nextTaskApiKey,
            nextCardName: result.nextTaskCardName,
            message: result.message,
          });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  enableSubmitButton = () => {
    this.setState({ submitIsDisabled: false });
  };

  disableSubmitButton = () => {
    this.setState({ submitIsDisabled: true });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <DeviceOrientation
        lockOrientation={"portrait"}
        onLockOrientation={(success: any) => {}}
        onOrientationChange={(orientation: any) => {
          this.setState({ orientation: orientation });
          console.log("device orientation changed to: ", orientation);
        }}
      >
        {/* Will only be in DOM in portrait */}
        <Orientation orientation="portrait" alwaysRender={false}>
          <div id="swipe">
            <Helmet>
              <title>Swipe | blindtest</title>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <body className="bg-gray-900"></body>
            </Helmet>

            <SwipeScreen
              setReset={this.clickReset}
              setSubmit={this.clickSubmit}
              setDeviceSupport={this.setDeviceIsSupported}
              setBrowserSupport={this.setBrowserIsSupported}
              setIsLoading={this.setIsLoading}
              orientation={this.state.orientation}
              apiKey={this.state.nextApiKey}
              cardName={this.state.nextCardName}
              message={this.state.message}
              enableButton={this.enableSubmitButton}
              disableButton={this.disableSubmitButton}
              deviceId={
                this.props.location.state ? this.props.location.state.id : ""
              }
            />

            <div
              id="overlay"
              className="absolute top-0 flex flex-wrap justify-between w-screen px-2 py-4"
              style={{ touchAction: "none" }}
            >
              <div className="flex justify-between w-screen">
                <button
                  className="px-3 py-2 text-white bg-red-900 rounded"
                  type="button"
                  onClick={() => {
                    this.clickReset(true);
                  }}
                >
                  Reset
                </button>
                <button
                  className="px-3 py-2 text-white bg-green-900 rounded disabled:opacity-25"
                  type="button"
                  disabled={this.state.submitIsDisabled}
                  onClick={() => {
                    this.clickSubmit(true);
                  }}
                >
                  Submit
                </button>
              </div>
              {this.showNotSupportedModal() ? (
                <NotSupportedModal
                  deviceIsSupported={this.state.deviceIsSupported}
                  browserIsSupported={this.state.browserIsSupported}
                />
              ) : null}
            </div>
            <LoadingAnimation isLoading={this.state.isLoading} />
          </div>
        </Orientation>

        {/* Will only be in DOM in landscape */}
        <Orientation orientation="landscape" alwaysRender={false}>
          <div>
            <div className="flex items-center justify-center mt-8">
              <img
                src={rotateDevice}
                alt="Please rotate your device."
                className="max-h-1/2-screen"
              />
            </div>
          </div>
        </Orientation>
      </DeviceOrientation>
    );
  }
}

export default Swipe;
