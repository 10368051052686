import React from "react";

//
type CardImageProps = {
  src: string;
  id: string;
  alt: string;
  scaleFactor: number;
};
type CardImageStates = {
  width: number;
};

class CardImage extends React.Component<CardImageProps, CardImageStates> {
  constructor(props: CardImageProps) {
    super(props);

    this.state = {
      width: 0,
    };
  }

  componentDidUpdate() {
    let img = new Image();
    img.src = this.props.src;
    img.onload = () => {
      this.setState({
        width: img.width,
      });
    };
  }

  render() {
    return (
      <div
        className="absolute object-left-top bg-white rounded-full"
        id="cardIconContainer"
        style={{
          opacity: 0,
        }}
      >
        <img
          id={this.props.id}
          src={this.props.src}
          alt={this.props.alt}
          className="m-4 select-none max-w-none"
          style={{
            opacity: 0,
            width: this.state.width * this.props.scaleFactor,
            top: 0,
            left: 0,
          }}
        />
      </div>
    );
  }
}

export default CardImage;
